export const Equalizer = () => {
    return (
        <section
            className='equalizer'>
            <img
                className='equalizer-img'
                src={'https://res.cloudinary.com/dk9b84f0u/image/upload/v1664386983/Symphny/ezgif.com-gif-maker_cbbaoz.gif'}
                alt='equalizer'
            />
        </section>
    )
}